import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const BlogList = ({ posts }) => {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="max-w-2xl mx-auto px-4 sm:px-6"
    >
      <div className="space-y-4">
        {posts.map((post) => (
          <motion.div 
            key={post.slug}
            whileHover={{ x: 4 }}
            transition={{ duration: 0.2 }}
          >
            <Link
              to={`/blog/${post.slug}`}
              className="flex items-center justify-between group"
            >
              <span className="text-base group-hover:text-orange-500 transition-colors">
                {post.title}
              </span>
              <span className="text-sm text-gray-500">
                {new Date(post.date).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short'
                })}
              </span>
            </Link>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default BlogList; 