import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  HiCommandLine,
  HiSquare2Stack,
  HiSparkles,
  HiLanguage,
  HiKey,
  HiCloud
} from 'react-icons/hi2';

const EasterEggContext = createContext();

const TIERS = {
  EXPLORER: { name: 'Explorer', multiplier: 1, minPoints: 0 },
  DISCOVERER: { name: 'Discoverer', multiplier: 1.5, minPoints: 30 },
  MASTER: { name: 'Master', multiplier: 2, minPoints: 75 }
};

// Total possible points: 75
export const easterEggs = {
  THEME_KEYBOARD: {
    id: 'THEME_KEYBOARD',
    name: 'Keyboard warrior',
    description: 'Used the "L" shortcut to toggle theme',
    points: 15,
    tier: TIERS.EXPLORER,
    icon: HiKey
  },
  COMMAND_PALETTE: {
    id: 'COMMAND_PALETTE',
    name: 'Power user',
    description: 'Used ⌘K to open command palette',
    points: 25,
    tier: TIERS.DISCOVERER,
    icon: HiCommandLine
  },
  WEATHER_EXPLORER: {
    id: 'WEATHER_EXPLORER',
    name: 'Weather watcher',
    description: 'Checked your local weather',
    points: 15,
    tier: TIERS.EXPLORER,
    icon: HiCloud
  },
  ESSAY_READER: {
    id: 'ESSAY_READER',
    name: 'Knowledge seeker',
    description: 'Read an essay from the blog',
    points: 20,
    tier: TIERS.EXPLORER,
    icon: HiSquare2Stack
  }
};

const TOTAL_POSSIBLE_POINTS = Object.values(easterEggs).reduce(
  (total, egg) => total + egg.points, 
  0
);

// Add a helper function to check if an egg can be discovered
const canDiscoverEgg = (eggId, discoveredEggs) => {
  return easterEggs[eggId] && Array.isArray(discoveredEggs) && !discoveredEggs.includes(eggId);
};

// Toast component for achievements
const Toast = ({ achievement, onHide }) => {
  useEffect(() => {
    const timer = setTimeout(onHide, 2000);
    return () => clearTimeout(timer);
  }, [onHide]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className="fixed inset-x-0 bottom-24 flex justify-center items-center z-50"
    >
      <div className="flex items-center gap-3 px-4 py-3 rounded-lg bg-black/90 text-white backdrop-blur-xl border border-white/[0.08] shadow-2xl font-['Inter']">
        <div className="p-2 rounded-lg bg-white/10">
          {achievement.icon && <achievement.icon className="w-4 h-4" />}
        </div>
        <div className="flex flex-col">
          <span className="text-sm font-medium tracking-tight">{achievement.name}</span>
          <span className="text-xs text-gray-400">{achievement.description}</span>
          <span className="text-xs text-green-400 mt-0.5">+{achievement.points} points</span>
        </div>
      </div>
    </motion.div>
  );
};

export function EasterEggProvider({ children }) {
  const [discoveredEggs, setDiscoveredEggs] = useState(() => {
    try {
      const saved = localStorage.getItem('discoveredEggs');
      const parsed = saved ? JSON.parse(saved) : [];
      return Array.isArray(parsed) ? parsed : [];
    } catch (error) {
      console.error('Error loading discovered eggs:', error);
      return [];
    }
  });
  
  const [showToast, setShowToast] = useState(false);
  const [currentAchievement, setCurrentAchievement] = useState(null);
  const [totalPoints, setTotalPoints] = useState(() => {
    try {
      const saved = localStorage.getItem('discoveredEggs');
      const eggs = saved ? JSON.parse(saved) : [];
      return Array.isArray(eggs) ? eggs.reduce((total, eggId) => total + (easterEggs[eggId]?.points || 0), 0) : 0;
    } catch (error) {
      console.error('Error calculating total points:', error);
      return 0;
    }
  });
  const [currentTier, setCurrentTier] = useState(() => {
    try {
      const saved = localStorage.getItem('discoveredEggs');
      const eggs = saved ? JSON.parse(saved) : [];
      const points = Array.isArray(eggs) ? eggs.reduce((total, eggId) => total + (easterEggs[eggId]?.points || 0), 0) : 0;
      if (points >= TIERS.MASTER.minPoints) return TIERS.MASTER;
      if (points >= TIERS.DISCOVERER.minPoints) return TIERS.DISCOVERER;
      return TIERS.EXPLORER;
    } catch (error) {
      console.error('Error calculating tier:', error);
      return TIERS.EXPLORER;
    }
  });

  const discoverEgg = useCallback((eggId) => {
    if (canDiscoverEgg(eggId, discoveredEggs)) {
      const egg = easterEggs[eggId];
      if (!egg) return; // Guard against invalid egg IDs

      setDiscoveredEggs(prev => {
        if (!Array.isArray(prev)) prev = []; // Ensure prev is an array
        const updated = [...prev, eggId];
        localStorage.setItem('discoveredEggs', JSON.stringify(updated));
        return updated;
      });
      
      // Update points and tier
      setTotalPoints(prev => {
        const newTotal = prev + egg.points;
        if (newTotal >= TIERS.MASTER.minPoints) setCurrentTier(TIERS.MASTER);
        else if (newTotal >= TIERS.DISCOVERER.minPoints) setCurrentTier(TIERS.DISCOVERER);
        return newTotal;
      });

      // Show achievement notification
      setCurrentAchievement(egg);
      setShowToast(true);
    }
  }, [discoveredEggs]);

  // Progress calculation
  const progress = {
    discovered: discoveredEggs.length,
    total: Object.keys(easterEggs).length,
    percentage: Math.round((discoveredEggs.length / Object.keys(easterEggs).length) * 100),
    possiblePoints: TOTAL_POSSIBLE_POINTS,
    earnedPoints: totalPoints,
    earnedPercentage: Math.round((totalPoints / TOTAL_POSSIBLE_POINTS) * 100)
  };

  // Persist discoveries
  useEffect(() => {
    localStorage.setItem('discoveredEggs', JSON.stringify(discoveredEggs));
  }, [discoveredEggs]);

  const resetEasterEggs = () => {
    setDiscoveredEggs([]);
    setTotalPoints(0);
    setCurrentTier(TIERS.EXPLORER);
    localStorage.removeItem('discoveredEggs');
  };

  return (
    <EasterEggContext.Provider value={{ 
      discoveredEggs, 
      discoverEgg, 
      totalPoints,
      currentTier,
      progress,
      TOTAL_POSSIBLE_POINTS,
      showToast,
      setShowToast,
      currentAchievement,
      resetEasterEggs
    }}>
      {children}
      <AnimatePresence>
        {showToast && currentAchievement && (
          <Toast 
            achievement={currentAchievement} 
            onHide={() => setShowToast(false)} 
          />
        )}
      </AnimatePresence>
    </EasterEggContext.Provider>
  );
}

export const useEasterEggs = () => useContext(EasterEggContext);