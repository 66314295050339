import React, { useEffect, useState, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import { PiArrowLeftThin, PiArrowRightThin } from 'react-icons/pi';
import { HiClipboardCopy, HiCheck } from 'react-icons/hi';
import { motion } from 'framer-motion';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight, atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { getAllPosts } from '../utils/posts';
import { useEasterEggs } from '../contexts/EasterEggContext';
import { Helmet } from 'react-helmet';
import { parseISO, format } from 'date-fns';

// Simple Table of Contents
const TableOfContents = ({ headings }) => (
  <div className="fixed left-8 top-32 w-48 hidden xl:block">
    <div className="text-sm text-gray-500 dark:text-gray-400 space-y-2">
      {headings.map((heading, index) => (
        <a
          key={index}
          href={`#${heading.id}`}
          className="block hover:text-orange-500 transition-colors duration-200"
        >
          {heading.text}
        </a>
      ))}
    </div>
  </div>
);

// Simple language colors for the dot
const languageColors = {
  javascript: '#F7DF1E',
  typescript: '#3178C6',
  python: '#3776AB',
  jsx: '#61DAFB',
  tsx: '#3178C6',
  css: '#1572B6',
  html: '#E34F26',
  json: '#292929'
};

const CodeBlock = ({ language, children }) => {
  const [copied, setCopied] = useState(false);
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDark(darkModeQuery.matches);
    
    const handler = (e) => setIsDark(e.matches);
    darkModeQuery.addEventListener('change', handler);
    return () => darkModeQuery.removeEventListener('change', handler);
  }, []);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(children);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const langColor = languageColors[language?.toLowerCase()] || '#6E7781';

  return (
    <div className="relative group rounded-lg bg-[#FAFAFA] dark:bg-[#0A0A0A] border border-[#E5E7EB] dark:border-[#1F1F1F] overflow-hidden">
      <div className="absolute top-0 inset-x-0 h-10 bg-[#F5F5F5] dark:bg-[#0F0F0F] border-b border-[#E5E7EB] dark:border-[#1F1F1F]">
        <div className="absolute top-0 left-4 h-10 flex items-center gap-2">
          <div className="w-2 h-2 rounded-full" style={{ backgroundColor: langColor }} />
          <span className="text-[11px] font-medium uppercase tracking-wider text-[#6E7781] dark:text-[#768390]">
            {language}
          </span>
        </div>
        <button
          onClick={handleCopy}
          className="absolute top-0 right-0 h-10 w-10 flex items-center justify-center text-[#6E7781] dark:text-[#768390] hover:text-[#24292F] dark:hover:text-[#E6E6E6] transition-colors duration-200"
        >
          {copied ? <HiCheck className="w-4 h-4" /> : <HiClipboardCopy className="w-4 h-4" />}
        </button>
      </div>
      <div className="pt-10">
        <SyntaxHighlighter
          language={language}
          style={isDark ? atomDark : oneLight}
          customStyle={{
            background: 'transparent',
            padding: '1rem',
            margin: 0,
            fontSize: '13px',
            lineHeight: '1.6',
            fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace'
          }}
          codeTagProps={{
            style: {
              background: 'transparent',
              whiteSpace: 'pre-wrap',
              lineHeight: 'inherit'
            }
          }}
          wrapLines={true}
          lineProps={{
            style: {
              background: 'transparent',
              wordBreak: 'break-word',
              whiteSpace: 'pre-wrap'
            }
          }}
        >
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

const BlogPost = ({ post }) => {
  const navigate = useNavigate();
  const [headings, setHeadings] = useState([]);
  const { discoverEgg, discoveredEggs } = useEasterEggs();
  
  // Memoize getAllPosts call
  const allPosts = useMemo(() => getAllPosts(), []);
  
  // Memoize the next post selection
  const nextPost = useMemo(() => {
    const otherPosts = allPosts.filter(p => p.slug !== post?.slug);
    if (otherPosts.length === 0) return null;
    return otherPosts[Math.floor(Math.random() * otherPosts.length)];
  }, [allPosts, post?.slug]);

  // Format the date with error handling
  const formattedDate = useMemo(() => {
    if (!post?.date) return 'Date not available';
    try {
      // Remove quotes if present and parse the date
      const cleanDate = post.date.replace(/"/g, '');
      return format(parseISO(cleanDate), 'MMMM d, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date not available';
    }
  }, [post?.date]);

  const handleNextPost = () => {
    if (nextPost) {
      navigate(`/blog/${nextPost.slug}`);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // Trigger Easter egg when user scrolls to bottom of essay
  useEffect(() => {
    if (!post) return;

    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const documentHeight = document.documentElement.scrollHeight;
      
      // If user has scrolled to bottom (with 100px threshold)
      if (documentHeight - scrollPosition < 100) {
        if (!discoveredEggs.includes('ESSAY_READER')) {
          discoverEgg('ESSAY_READER');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [post, discoveredEggs, discoverEgg]);

  useEffect(() => {
    if (post?.content) {
      const matches = post.content.match(/###\s+(.+)$/gm) || [];
      const extracted = matches.map(match => ({
        id: match.replace('### ', '').toLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, '-'),
        text: match.replace('### ', '')
      }));
      setHeadings(extracted);
    }
  }, [post?.content]);

  if (!post) {
    return (
      <div className="max-w-2xl mx-auto px-4 sm:px-6 py-12">
        <div className="text-center">
          <h1 className="text-2xl font-bold mb-4">Post not found</h1>
          <p className="text-gray-500">The post you're looking for doesn't exist or has been moved.</p>
        </div>
      </div>
    );
  }

  const ogImageUrl = `${window.location.origin}/api/og?title=${encodeURIComponent(post.title)}`;

  return (
    <div className="relative">
      <Helmet>
        <title>{post.title} - Prateek Keshari</title>
        <meta name="description" content={post.content.slice(0, 160)} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.content.slice(0, 160)} />
        <meta property="og:image" content={ogImageUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.content.slice(0, 160)} />
        <meta name="twitter:image" content={ogImageUrl} />
      </Helmet>
      
      <TableOfContents headings={headings} />
      <article className="max-w-2xl mx-auto px-4 sm:px-6">
        <motion.button
          onClick={() => navigate('/blog')}
          className="group flex items-center gap-2 mt-4 mb-4 relative text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200"
          whileHover="hover"
          initial="initial"
          variants={{
            initial: { x: 0 },
            hover: { x: 0 }
          }}
        >
          <motion.span
            variants={{
              initial: { x: 0 },
              hover: { x: -4 }
            }}
            transition={{ duration: 0.15 }}
          >
            <PiArrowLeftThin className="w-4 h-4" />
          </motion.span>
          <div className="relative overflow-hidden h-5 w-[80px]">
            <motion.span
              className="absolute top-0 left-0"
              variants={{
                initial: { y: 0, opacity: 1 },
                hover: { y: -8, opacity: 0 }
              }}
              transition={{ duration: 0.2, ease: [0.23, 1, 0.32, 1] }}
            >
              Back
            </motion.span>
            <motion.span
              className="absolute top-0 left-0"
              variants={{
                initial: { y: 8, opacity: 0 },
                hover: { y: 0, opacity: 1 }
              }}
              transition={{ duration: 0.2, ease: [0.23, 1, 0.32, 1] }}
            >
              All Essays
            </motion.span>
          </div>
        </motion.button>

        <h1 className="text-3xl font-bold mb-4">{post.title}</h1>
        <div className="text-sm text-gray-500 dark:text-gray-400 mb-8">
          {formattedDate}
        </div>

        <div className="prose dark:prose-invert max-w-none prose-headings:text-xl prose-p:text-base prose-p:leading-relaxed prose-li:text-base prose-pre:bg-transparent prose-pre:p-0 dark:prose-pre:bg-transparent">
          <ReactMarkdown
            components={{
              h3: ({children, ...props}) => {
                const id = children.toString().toLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, '-');
                return (
                  <h3 id={id} className="text-xl font-semibold mt-8 mb-4" {...props}>
                    {children}
                  </h3>
                );
              },
              code: ({node, inline, className, children, ...props}) => {
                const match = /language-(\w+)/.exec(className || '');
                return !inline && match ? (
                  <CodeBlock language={match[1]}>
                    {String(children).replace(/\n$/, '')}
                  </CodeBlock>
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              },
              img: ({src, alt}) => (
                <img 
                  src={src} 
                  alt={alt} 
                  className="w-full rounded-2xl my-8"
                />
              )
            }}
          >
            {post.content}
          </ReactMarkdown>
        </div>

        {nextPost && (
          <motion.div 
            className="mt-16 pt-16 border-t dark:border-neutral-800"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <motion.button
              onClick={handleNextPost}
              className="group flex flex-col items-start w-full"
              whileHover="hover"
              initial="initial"
            >
              <div className="text-xs uppercase tracking-wider opacity-50 mb-2">next</div>
              <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-200">
                <span className="text-sm">{nextPost.title}</span>
                <motion.span
                  variants={{
                    initial: { x: 0 },
                    hover: { x: 4 }
                  }}
                  transition={{ duration: 0.2 }}
                >
                  <PiArrowRightThin className="w-4 h-4 flex-shrink-0" />
                </motion.span>
              </div>
            </motion.button>
          </motion.div>
        )}
      </article>
    </div>
  );
};

export default BlogPost; 
