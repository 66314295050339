import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  HiOutlineCommandLine, 
  HiCheck, 
  HiCalculator, 
  HiClock, 
  HiCurrencyDollar, 
  HiGlobeAlt, 
  HiClipboard, 
  HiMoon, 
  HiEnvelope, 
  HiDocumentText, 
  HiPhoto, 
  HiCloud, 
  HiCodeBracket, 
  HiArrowRight, 
  HiSparkles,
  HiOutlineSparkles,
  HiSun,
  HiArrowPath,
  HiMagnifyingGlass,
  HiCalendar,
  HiTrash
} from 'react-icons/hi2';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import cityTimezones from 'city-timezones';
import profilePic from '../images/profile.png';
import profilePicLight from '../images/profile-light.jpg';
import ReactMarkdown from 'react-markdown';
import { useEasterEggs } from '../contexts/EasterEggContext';
import { getAllPosts } from '../utils/posts';
import { useNavigate } from 'react-router-dom';

// Add these constants near the top
const MAX_REQUESTS_PER_MINUTE = 10;
const BLOCKED_KEYWORDS = ['hack', 'exploit', 'attack', 'malware', 'password'];
const PRATEEK_CONTEXT = `
Prateek Keshari is a product and marketing leader based in Berlin. He helps companies with marketing that sticks and believes in making, leading, and taking things from 0 to 1. He has a deep passion for good tech and design, often experimenting with AI, code, design, photo, and film. He values high agency, clarity, creativity and big-picture thinking. He currently works at GetYourGuide and has built several projects including Radio Globe, Time, Scoop, Mockmint, and Peek AI.
`;

// Add this helper function near the top of the file
const searchCommands = (commands, query) => {
  if (!query) return commands;
  
  const normalizedQuery = query.toLowerCase().trim();
  
  return commands.map(group => ({
    ...group,
    commands: group.commands.filter(command => {
      const nameMatch = command.name.toLowerCase().includes(normalizedQuery);
      const descriptionMatch = command.description?.toLowerCase().includes(normalizedQuery);
      return nameMatch || descriptionMatch;
    })
  })).filter(group => group.commands.length > 0);
};

// Enhanced fuzzy search helper with better scoring and normalization
const fuzzyMatch = (text, query) => {
  // Normalize both text and query
  const normalizedText = text.toLowerCase().trim()
    .replace(/[^\w\s]/g, '') // Remove special characters
    .replace(/\s+/g, ' '); // Normalize whitespace
  const normalizedQuery = query.toLowerCase().trim()
    .replace(/[^\w\s]/g, '')
    .replace(/\s+/g, ' ');
    
  if (!normalizedQuery) return 1; // Empty query matches everything
  if (!normalizedText) return 0;
  
  let score = 0;
  let lastIndex = -1;
  let consecutiveMatches = 0;
  let wordBoundaryMatches = 0;
  
  // Split into words for word boundary matching
  const words = normalizedText.split(' ');
  const queryWords = normalizedQuery.split(' ');
  
  // Check for word boundary matches
  queryWords.forEach(queryWord => {
    words.forEach(word => {
      if (word.startsWith(queryWord)) {
        wordBoundaryMatches++;
      }
    });
  });
  
  // Check for consecutive character matches
  for (let i = 0; i < normalizedQuery.length; i++) {
    const index = normalizedText.indexOf(normalizedQuery[i], lastIndex + 1);
    if (index === -1) return 0;
    
    // Increase score for consecutive matches
    if (lastIndex !== -1 && index === lastIndex + 1) {
      consecutiveMatches++;
      score += consecutiveMatches * 2; // Bonus for consecutive matches
    } else {
      consecutiveMatches = 0;
      score += 1;
    }
    
    // Bonus for word boundary matches
    if (index === 0 || normalizedText[index - 1] === ' ') {
      score += 3;
    }
    
    lastIndex = index;
  }
  
  // Add word boundary bonus to final score
  score += wordBoundaryMatches * 5;
  
  // Normalize score based on text length difference
  const lengthDifference = Math.abs(normalizedText.length - normalizedQuery.length);
  score = score / (1 + (lengthDifference * 0.1));
  
  return score;
};

// Enhanced excerpt helper with better context
const getExcerpt = (content, query, maxLength = 150) => {
  if (!content) return '';
  
  const normalizedContent = content.toLowerCase();
  const normalizedQuery = query.toLowerCase();
  
  if (!query) {
    // Return first sentence or maxLength characters if no query
    const firstSentence = content.match(/^[^.!?]+[.!?]/);
    if (firstSentence) {
      return firstSentence[0].length > maxLength 
        ? firstSentence[0].slice(0, maxLength) + '...'
        : firstSentence[0];
    }
    return content.slice(0, maxLength) + '...';
  }
  
  const index = normalizedContent.indexOf(normalizedQuery);
  if (index === -1) {
    // If query not found, look for partial matches
    const words = query.split(' ');
    for (const word of words) {
      const wordIndex = normalizedContent.indexOf(word.toLowerCase());
      if (wordIndex !== -1) {
        const start = Math.max(0, wordIndex - 60);
        const end = Math.min(content.length, wordIndex + maxLength - 60);
        return (start > 0 ? '...' : '') + content.slice(start, end) + (end < content.length ? '...' : '');
      }
    }
    return content.slice(0, maxLength) + '...';
  }
  
  const start = Math.max(0, index - 60);
  const end = Math.min(content.length, index + maxLength - 60);
  return (start > 0 ? '...' : '') + content.slice(start, end) + (end < content.length ? '...' : '');
};

// Enhanced highlight helper with better matching
const highlightMatch = (text, query) => {
  if (!query || !text) return text;
  
  // Split query into words
  const queryWords = query.toLowerCase().trim().split(/\s+/);
  
  // Create a regex pattern that matches any of the query words
  const pattern = new RegExp(`(${queryWords.map(word => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|')})`, 'gi');
  
  const parts = text.split(pattern);
  
  return parts.map((part, i) => {
    const isMatch = queryWords.some(word => part.toLowerCase() === word);
    return isMatch ? (
      <mark key={i} className="bg-yellow-200/30 dark:bg-yellow-200/20 rounded px-0.5">{part}</mark>
    ) : part;
  });
};

// Add reading time calculator helper
const calculateReadingTime = (text) => {
  const wordsPerMinute = 200;
  const words = text.trim().split(/\s+/).length;
  const minutes = Math.ceil(words / wordsPerMinute);
  return minutes === 1 ? '1 min read' : `${minutes} min read`;
};

function CommandPalette({ 
  isOpen, 
  setIsOpen, 
  isDarkMode, 
  projects,
  setIsDarkMode
}) {
  const { discoverEgg, discoveredEggs, resetEasterEggs, totalPoints, progress } = useEasterEggs();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [copiedId, setCopiedId] = useState(null);
  const [calculationResult, setCalculationResult] = useState(null);
  const [exchangeRates, setExchangeRates] = useState(null);
  const [weatherData, setWeatherData] = useState(null);
  const [userLocation, setUserLocation] = useState('Berlin');
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const [requestCount, setRequestCount] = useState(0);
  const [lastRequestTime, setLastRequestTime] = useState(Date.now());
  const navigate = useNavigate();
  const [posts, setPosts] = useState(getAllPosts());

  // Helper function to format calculation results - moved to top
  const formatCalculationResult = (result) => {
    if (result === null) return '';
    if (Number.isInteger(result)) return result.toString();
    return result.toFixed(2);
  };

  // Add calculator command creation function
  const createCalculatorCommand = () => ({
    id: 'calculation',
    name: `= ${formatCalculationResult(calculationResult)}`,
    description: 'Press Enter to copy result',
    icon: HiCalculator,
    action: async () => {
      if (calculationResult !== null) {
        await navigator.clipboard.writeText(formatCalculationResult(calculationResult));
        setCopiedId('calculation');
        setTimeout(() => setCopiedId(null), 1000);
      }
    }
  });

  const aboutText = "I help companies with marketing that sticks. I believe in making, leading, and taking things from 0 to 1. I have a deep passion for good tech and design. I am often learning and experimenting with AI, code, design, photo, and film. I value high agency, clarity, creativity and big-picture thinking.";

  // Modified createCopyCommand helper function - removed auto-close
  const createCopyCommand = (id, name, text) => ({
    id,
    name,
    action: async () => {
      await navigator.clipboard.writeText(text);
      setCopiedId(id);
      setTimeout(() => {
        setCopiedId(null);  // Just clear the copied state, don't close
      }, 1000);
    }
  });

  // Enhanced safe calculation function
  const calculateExpression = (query) => {
    try {
      if (!query) return null;

      // Handle percentage calculations with various formats
      if (query.includes('%')) {
        // Remove any extra spaces and convert to lowercase
        const cleanQuery = query.toLowerCase().trim().replace(/\s+/g, ' ');
        
        // Handle "X% of Y" format
        if (cleanQuery.includes(' of ')) {
          const [percentPart, valuePart] = cleanQuery.split(' of ');
          const percentage = parseFloat(percentPart);
          const value = parseFloat(valuePart);
          if (!isNaN(percentage) && !isNaN(value)) {
            return (percentage / 100) * value;
          }
        }
        
        // Handle "X% on Y" format
        if (cleanQuery.includes(' on ')) {
          const [percentPart, valuePart] = cleanQuery.split(' on ');
          const percentage = parseFloat(percentPart);
          const value = parseFloat(valuePart);
          if (!isNaN(percentage) && !isNaN(value)) {
            return (percentage / 100) * value;
          }
        }
        
        // Handle simple percentage (just converting to decimal)
        const value = parseFloat(query);
        if (!isNaN(value)) {
          return value / 100;
        }
      }
      
      // Handle basic arithmetic safely
      const sanitizedQuery = query
        .replace(/[×x]/g, '*')
        .replace(/[÷]/g, '/')
        .replace(/[^0-9+\-*/.() ]/g, '');

      // Prevent malicious code execution
      if (sanitizedQuery.includes('function') || sanitizedQuery.includes('=>')) {
        return null;
      }

      // Safe evaluation
      return new Function(`return ${sanitizedQuery}`)();
    } catch (error) {
      return null;
    }
  };

  // Check if query is a calculation
  useEffect(() => {
    if (searchQuery.match(/^[\d\s+\-*/%()×x.]+$/) || 
        searchQuery.toLowerCase().includes('% of ') ||
        searchQuery.toLowerCase().includes('% on ')) {
      const result = calculateExpression(searchQuery);
      setCalculationResult(result);
    } else {
      setCalculationResult(null);
    }
  }, [searchQuery]);

  // Helper function for timezone conversions
  const getTimeInTimezone = (timezone) => {
    const now = new Date();
    return formatInTimeZone(now, timezone, 'h:mm a');
  };

  // Helper function to parse time queries
  const parseTimeQuery = (query) => {
    const normalizedQuery = query.toLowerCase().trim();
    
    if (normalizedQuery.includes('time')) {
      const cityQuery = normalizedQuery
        .replace('time in', '')
        .replace('time at', '')
        .trim();

      const cityResults = cityTimezones.lookupViaCity(cityQuery);
      
      if (cityResults && cityResults.length > 0) {
        const city = cityResults[0];
        const timeString = getTimeInTimezone(city.timezone);
        return {
          id: `time-${city.city}`,
          name: `${city.city}, ${city.country}: ${timeString}`,
          description: `Current time in ${city.city}`,
          icon: HiClock,
          // Add action to copy time to clipboard
          action: async () => {
            await navigator.clipboard.writeText(timeString);
            setCopiedId(`time-${city.city}`);
            setTimeout(() => {
              setCopiedId(null);
            }, 1000);
          }
        };
      }

      // Handle multiple matches
      const allCities = cityTimezones.cityMapping;
      const possibleMatches = allCities.filter(city => 
        city.city.toLowerCase().includes(cityQuery) ||
        city.country.toLowerCase().includes(cityQuery)
      ).slice(0, 5);

      if (possibleMatches.length > 0) {
        return possibleMatches.map(city => {
          const timeString = getTimeInTimezone(city.timezone);
          return {
            id: `time-${city.city}`,
            name: `${city.city}, ${city.country}: ${timeString}`,
            description: `Current time in ${city.city}`,
            icon: HiClock,
            // Add action to copy time to clipboard
            action: async () => {
              await navigator.clipboard.writeText(timeString);
              setCopiedId(`time-${city.city}`);
              setTimeout(() => {
                setCopiedId(null);
              }, 1000);
            }
          };
        });
      }
    }

    return null;
  };

  // Create time command if query matches
  const timeCommand = parseTimeQuery(searchQuery);
  const timeCommands = Array.isArray(timeCommand) ? timeCommand : timeCommand ? [timeCommand] : [];

  // Modified calculation command - removed auto-close
  const calculationCommand = calculationResult !== null ? [{
    id: 'calculation',
    name: `= ${formatCalculationResult(calculationResult)}`,
    description: searchQuery,
    icon: HiCalculator,
    action: async () => {
      await navigator.clipboard.writeText(calculationResult.toString());
      setCopiedId('calculation');
      setTimeout(() => {
        setCopiedId(null);  // Just clear the copied state, don't close
      }, 1000);
    }
  }] : [];

  // Fetch exchange rates on component mount
  useEffect(() => {
    const fetchRates = async () => {
      try {
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
        const data = await response.json();
        setExchangeRates(data.rates);
      } catch (error) {
        console.error('Error fetching exchange rates:', error);
      }
    };
    fetchRates();
  }, []);

  // Modified currency command - removed auto-close
  const parseCurrencyQuery = (query) => {
    const normalizedQuery = query.toLowerCase().trim();
    
    // Match patterns like "100 usd to eur" or "convert 50 gbp to inr"
    const currencyPattern = /(?:convert\s+)?(\d+(?:\.\d+)?)\s*([a-z]{3})\s+(?:to|in)\s+([a-z]{3})/i;
    const match = normalizedQuery.match(currencyPattern);

    if (match && exchangeRates) {
      const [_, amount, fromCurrency, toCurrency] = match;
      const fromRate = exchangeRates[fromCurrency.toUpperCase()];
      const toRate = exchangeRates[toCurrency.toUpperCase()];

      if (fromRate && toRate) {
        // Convert through USD as base
        const inUSD = parseFloat(amount) / fromRate;
        const result = inUSD * toRate;
        
        return {
          id: 'currency-conversion',
          name: `${amount} ${fromCurrency.toUpperCase()} = ${result.toFixed(2)} ${toCurrency.toUpperCase()}`,
          description: `Press Enter to copy result`,
          icon: HiCurrencyDollar,
          action: async () => {
            await navigator.clipboard.writeText(result.toFixed(2));
            setCopiedId('currency-conversion');
            setTimeout(() => setCopiedId(null), 1000);
          }
        };
      }
    }

    return null;
  };

  // Create currency command if query matches
  const currencyCommand = parseCurrencyQuery(searchQuery) ? [parseCurrencyQuery(searchQuery)] : [];

  // Expanded Berlin facts
  const berlinFacts = [
    {
      fact: "Berlin has more bridges than Venice",
      source: "Over 1,700 bridges compared to Venice's 400"
    },
    {
      fact: "Berlin's TV Tower is visible from every district",
      source: "Standing at 368 meters tall"
    },
    {
      fact: "Berlin has more museums than rainy days per year",
      source: "175 museums vs 106 rainy days"
    },
    {
      fact: "The Berlin Wall stood for 10,316 days",
      source: "From August 13, 1961, to November 9, 1989"
    },
    {
      fact: "Berlin has more kebab shops than Istanbul",
      source: "Over 4,000 döner shops in the city"
    },
    {
      fact: "The first traffic light in Europe was in Berlin",
      source: "Installed at Potsdamer Platz in 1924"
    },
    {
      fact: "Berlin's Hauptbahnhof is Europe's largest train station",
      source: "Spanning across 5 floors"
    },
    {
      fact: "Berlin is 9 times bigger than Paris",
      source: "892 km² vs 105 km²"
    }
  ];

  // Modified natural language command parser
  const parseNaturalCommand = (query) => {
    const normalizedQuery = query.toLowerCase();
    
    // Greetings in different languages
    const greetings = ['hello', 'hey', 'guten tag', 'hola', 'bonjour'];
    if (greetings.some(greeting => normalizedQuery.trim() === greeting)) { // Added trim() to handle whitespace
      return {
        id: 'greeting',
        name: ` Hello! Guten Tag! Hola! Bonjour!`,
        description: 'Try asking me to calculate something or check time anywhere', 
        action: () => {}
      };
    }

    // Easter eggs
    if (normalizedQuery.includes('meaning of life')) {
      return {
        id: 'meaning',
        name: '42',
        description: "That's the answer to life, universe, and everything",
        action: () => {}
      };
    }

    // Quick actions
    if (normalizedQuery.includes('dark') || normalizedQuery.includes('light')) {
      return {
        id: 'theme-natural',
        name: `Switch to ${isDarkMode ? 'light' : 'dark'} mode`,
        description: 'Change the appearance',
        action: () => setIsDarkMode(prev => !prev)
      };
    }

    // Modified Berlin facts handler - returns just one random fact
    if (normalizedQuery.includes('berlin') || normalizedQuery.includes('fact')) {
      // Get a random fact
      const randomFact = berlinFacts[Math.floor(Math.random() * berlinFacts.length)];
      
      // Return a single command object
      return {
        id: 'berlin-fact',
        name: randomFact.fact,
        description: randomFact.source,
        icon: ({ className }) => (
          <span className={className} role="img" aria-label="Berlin">
            🇩🇪
          </span>
        )
      };
    }

    return null;
  };

  // Modified naturalCommand creation
  const naturalCommand = parseNaturalCommand(searchQuery);
  const naturalCommands = naturalCommand ? [naturalCommand] : [];

  // Helper function to copy image to clipboard
  const copyImageToClipboard = async (imageUrl) => {
    try {
      // Create an image element
      const img = new Image();
      
      // Set crossOrigin to anonymous since we're loading from the same origin
      img.crossOrigin = "anonymous";
      
      // Create a promise to handle image loading
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = imageUrl;
      });

      // Create a canvas and draw the image
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      // Convert to blob
      const blob = await new Promise(resolve => 
        canvas.toBlob(resolve, 'image/png', 1.0)
      );

      // Write to clipboard
      await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob
        })
      ]);

      return true;
    } catch (error) {
      console.error('Error copying image:', error);
      return false;
    }
  };

  // Use ref to store randomized commands
  const randomizedCommandsRef = useRef(null);

  // Update blog search functionality
  const searchBlogPosts = (query) => {
    // Show all posts if no query (just "blog:")
    if (!query) return posts
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(0, 5)
      .map(post => ({
        id: `blog-${post.slug}`,
        name: post.title.replace(/[#*_]/g, ''), // Remove markdown characters
        description: `${format(new Date(post.date), 'MMMM d, yyyy')} · ${calculateReadingTime(post.content)}`,
        secondaryDescription: getExcerpt(post.content.replace(/[#*_]/g, ''), ''), // Remove markdown characters
        icon: HiDocumentText,
        action: () => {
          navigate(`/blog/${post.slug}`);
          setIsOpen(false);
        }
      }));
    
    const normalizedQuery = query.toLowerCase().trim();
    
    return posts
      .map(post => {
        const titleScore = fuzzyMatch(post.title.replace(/[#*_]/g, ''), normalizedQuery) * 2; // Title matches worth more
        const contentScore = fuzzyMatch(post.content.replace(/[#*_]/g, ''), normalizedQuery);
        const totalScore = titleScore + contentScore;
        
        return {
          post,
          score: totalScore
        };
      })
      .filter(({ score }) => score > 0)
      .sort((a, b) => b.score - a.score)
      .slice(0, 5)
      .map(({ post }) => ({
        id: `blog-${post.slug}`,
        name: highlightMatch(post.title.replace(/[#*_]/g, ''), normalizedQuery),
        description: `${format(new Date(post.date), 'MMMM d, yyyy')} · ${calculateReadingTime(post.content)}`,
        secondaryDescription: highlightMatch(getExcerpt(post.content.replace(/[#*_]/g, ''), normalizedQuery), normalizedQuery),
        icon: HiDocumentText,
        action: () => {
          navigate(`/blog/${post.slug}`);
          setIsOpen(false);
        }
      }));
  };

  // Add these command groups inside the useMemo hook
  const commandGroups = React.useMemo(() => {
    const appearanceCommands = {
      name: "Appearance",
      commands: [
        {
          id: 'theme',
          name: `Switch to ${isDarkMode ? 'light' : 'dark'} mode`,
          description: `Currently in ${isDarkMode ? 'dark' : 'light'} mode`,
          icon: isDarkMode ? HiSun : HiMoon,
          rightIcon: ({ className }) => (
            <kbd className={`${className} text-[11px] font-medium font-['Inter'] opacity-50`}>
              L
            </kbd>
          ),
          action: () => setIsDarkMode(prev => !prev)
        }
      ]
    };

    const essentialCommands = {
      name: "Tools",
      commands: [
        {
          id: 'blog-search',
          name: 'Search essays',
          description: 'Search through all blog posts',
          icon: HiMagnifyingGlass,
          action: () => setSearchQuery('blog:')
        },
        {
          id: 'calculator',
          name: 'Calculate',
          description: 'Try: 2 + 2 or 34% of 567',
          icon: HiCalculator,
          action: () => setSearchQuery('34% of 567')
        },
        {
          id: 'time',
          name: 'Check time anywhere',
          description: 'Try: time in tokyo or time in berlin',
          icon: HiClock,
          action: () => setSearchQuery('time in tokyo')
        },
        {
          id: 'weather',
          name: 'Current weather',
          description: weatherData && weatherData.main && weatherData.weather ? 
            `${userLocation}: ${Math.round(weatherData.main.temp)}°C, ${weatherData.weather[0].description}` :
            'Loading weather...',
          icon: HiCloud,
          action: () => {
            if (weatherData && weatherData.main && weatherData.weather) {
              const weatherText = `Current weather in ${userLocation}: ${Math.round(weatherData.main.temp)}°C, ${weatherData.weather[0].description}`;
              navigator.clipboard.writeText(weatherText);
              setCopiedId('weather');
              setTimeout(() => setCopiedId(null), 1000);
            }
          }
        },
        {
          id: 'currency',
          name: 'Convert currency',
          description: 'Try: 100 usd to eur',
          icon: HiCurrencyDollar,
          action: () => setSearchQuery('100 usd to eur')
        }
      ]
    };

    const projectCommands = {
      name: "Recent Projects",
      commands: [
        {
          id: 'visit-berlin',
          name: 'Berlin Emergency Tracker',
          icon: HiCodeBracket,
          action: () => window.open('https://berlin.prateekkeshari.com', '_blank')
        },
        {
          id: 'visit-radio-cast',
          name: 'Radiocast',
          icon: HiCodeBracket,
          action: () => window.open('https://radiocast.co', '_blank')
        },
        {
          id: 'visit-time',
          name: 'Time',
          icon: HiCodeBracket,
          action: () => window.open('https://time.prateekkeshari.com', '_blank')
        },
        {
          id: 'visit-scoop',
          name: 'Scoop',
          icon: HiCodeBracket,
          action: () => window.open('https://scoop.prateekkeshari.com', '_blank')
        },
        {
          id: 'visit-mockmint',
          name: 'Mockmint',
          icon: HiCodeBracket,
          action: () => window.open('https://mockmint.prateekkeshari.com', '_blank')
        },
        {
          id: 'visit-peek',
          name: 'Peek AI',
          icon: HiCodeBracket,
          action: () => window.open('https://prateekkeshari.gumroad.com/l/peek', '_blank')
        }
      ]
    };

    const navigationCommands = {
      name: "Visit",
      commands: [
        {
          id: 'visit-gyg',
          name: 'GetYourGuide',
          icon: HiGlobeAlt,
          action: () => window.open('https://www.getyourguide.com', '_blank')
        },
        {
          id: 'visit-linkedin',
          name: 'LinkedIn',
          icon: HiGlobeAlt,
          action: () => window.open('https://linkedin.com/in/prateekkeshari', '_blank')
        },
        {
          id: 'visit-github',
          name: 'GitHub',
          icon: HiGlobeAlt,
          action: () => window.open('https://github.com/prateekkeshari', '_blank')
        },
        {
          id: 'visit-twitter',
          name: 'Twitter',
          icon: HiGlobeAlt,
          action: () => window.open('https://twitter.com/prkeshari', '_blank')
        },
        {
          id: 'visit-threads',
          name: 'Threads',
          icon: HiGlobeAlt,
          action: () => window.open('https://www.threads.net/@prateekkeshari', '_blank')
        }
      ]
    };

    const contactCommands = {
      name: "Contact",
      commands: [
        {
          id: 'send-email',
          name: 'Send email',
          icon: HiEnvelope,
          action: () => window.open('mailto:hi@prateekkeshari.com', '_blank')
        },
        {
          id: 'copy-email',
          name: 'Copy email',
          icon: HiEnvelope,
          action: async () => {
            await navigator.clipboard.writeText('hi@prateekkeshari.com');
            setCopiedId('copy-email');
            setTimeout(() => setCopiedId(null), 1000);
          }
        }
      ]
    };

    const copyCommands = {
      name: "Details",
      commands: [
        {
          id: 'copy-about',
          name: 'Copy bio',
          icon: HiDocumentText,
          action: async () => {
            await navigator.clipboard.writeText(aboutText);
            setCopiedId('copy-about');
            setTimeout(() => setCopiedId(null), 1000);
          }
        },
        {
          id: 'copy-image',
          name: 'Copy profile picture',
          icon: HiPhoto,
          action: async () => {
            const imageUrl = isDarkMode ? profilePic : profilePicLight;
            const success = await copyImageToClipboard(imageUrl);
            if (success) {
              setCopiedId('copy-image');
              setTimeout(() => setCopiedId(null), 1000);
            }
          }
        }
      ]
    };

    const actionsCommands = {
      name: 'Actions',
      commands: [
        {
          id: 'reset-eggs',
          name: 'Reset Easter Eggs',
          description: `Reset all discovered Easter eggs (currently ${totalPoints}/${progress.possiblePoints} points)`,
          icon: HiTrash,
          action: () => {
            resetEasterEggs();
            setIsOpen(false);
          }
        },
        // ... other action commands ...
      ]
    };

    return [appearanceCommands, essentialCommands, projectCommands, navigationCommands, contactCommands, copyCommands, actionsCommands];
  }, [isDarkMode, setIsDarkMode, aboutText, weatherData, userLocation, navigate, totalPoints, progress, resetEasterEggs]);

  // Update the filteredCommands useMemo to include blog search
  const filteredCommands = React.useMemo(() => {
    const filteredGroups = [];

    // Add blog search results if query starts with 'blog:'
    if (searchQuery.toLowerCase().startsWith('blog:')) {
      const blogQuery = searchQuery.slice(5).trim();
      const blogResults = searchBlogPosts(blogQuery);
      
      if (blogResults.length > 0) {
        filteredGroups.push({
          name: blogQuery ? 'Search Results' : 'Recent Essays',
          commands: blogResults
        });
      } else {
        filteredGroups.push({
          name: 'Essays',
          commands: [{
            id: 'no-results',
            name: 'No essays found',
            description: 'Try searching with different keywords',
            icon: HiDocumentText,
            action: () => {}
          }]
        });
      }
      return filteredGroups;
    }

    // Add calculator group if there's a calculation
    if (calculationResult !== null) {
      filteredGroups.push({
        name: 'Calculator',
        commands: [createCalculatorCommand()]
      });
    }

    // Add currency group if there's a currency command
    if (currencyCommand.length > 0) {
      filteredGroups.push({
        name: 'Currency',
        commands: currencyCommand
      });
    }

    // Add time commands if present
    if (timeCommands.length > 0) {
      filteredGroups.push({
        name: 'Time',
        commands: timeCommands
      });
    }

    // Add natural language commands if present
    if (naturalCommands.length > 0) {
      filteredGroups.push({
        name: 'Suggestions',
        commands: naturalCommands
      });
    }

    // Only add regular command groups if not in blog search mode
    if (!searchQuery.toLowerCase().startsWith('blog:')) {
      const searchResults = searchCommands(commandGroups, searchQuery);
      filteredGroups.push(...searchResults);
    }

    return filteredGroups;
  }, [
    calculationResult, 
    currencyCommand, 
    timeCommands, 
    naturalCommands,
    searchQuery, 
    commandGroups, 
    isDarkMode, 
    copiedId,
    posts
  ]);

  // Add ref for the commands list container
  const commandsListRef = React.useRef(null);

  // Add function to scroll selected item into view
  const scrollSelectedIntoView = (index) => {
    if (!commandsListRef.current) return;
    
    const commandElements = commandsListRef.current.children;
    if (commandElements[index]) {
      commandElements[index].scrollIntoView({
        block: 'nearest',
        behavior: 'smooth'
      });
    }
  };

  // Add this helper function to get flattened commands
  const getFlattenedCommands = (groups) => {
    let flattenedCommands = [];
    let groupIndexMap = new Map(); // Maps command index to its group index
    
    groups.forEach((group, groupIndex) => {
      group.commands.forEach((command) => {
        flattenedCommands.push(command);
        groupIndexMap.set(flattenedCommands.length - 1, groupIndex);
      });
    });
    
    return { flattenedCommands, groupIndexMap };
  };

  // Update the keyboard navigation useEffect
  useEffect(() => {
    if (!isOpen) return;

    const handleKeyDown = (e) => {
      // Add blog search shortcut
      if (e.key === 'b' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setSearchQuery('blog:');
        return;
      }
      
      // Only handle Escape and Arrow keys here
      switch (e.key) {
        case 'Escape':
          setIsOpen(false);
          break;
        case 'ArrowDown':
          e.preventDefault();
          setSelectedIndex(prev => {
            const newIndex = prev + 1;
            scrollSelectedIntoView(newIndex);
            return newIndex;
          });
          break;
        case 'ArrowUp':
          e.preventDefault();
          setSelectedIndex(prev => {
            const newIndex = Math.max(0, prev - 1);
            scrollSelectedIntoView(newIndex);
            return newIndex;
          });
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, setIsOpen]);

  // Reset selected index when search query changes
  useEffect(() => {
    setSelectedIndex(0);
  }, [searchQuery]);

  // Add IP location fetch
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) throw new Error('Location fetch failed');
        const data = await response.json();
        if (data.city) {
          setUserLocation(data.city);
        }
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };
    fetchLocation();
  }, []);

  // Update weather fetch to use user's location
  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const response = await fetch(`/api/weather?city=${userLocation}`);
        if (!response.ok) throw new Error('Weather fetch failed');
        const data = await response.json();
        setWeatherData(data);
      } catch (error) {
        console.error('Error fetching weather:', error);
        setWeatherData(null);
      }
    };

    if (isOpen) {
      fetchWeather();
    }
  }, [isOpen, userLocation]);

  // Add click outside handler
  const commandPaletteRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (commandPaletteRef.current && !commandPaletteRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, setIsOpen]);

  // Add this useEffect after the other fetch effects
  useEffect(() => {
    const fetchRates = async () => {
      try {
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
        if (!response.ok) throw new Error('Exchange rates fetch failed');
        const data = await response.json();
        setExchangeRates(data.rates);
      } catch (error) {
        console.error('Error fetching exchange rates:', error);
      }
    };

    if (isOpen) {
      fetchRates();
    }
  }, [isOpen]);

  // Update handleMouseMove to work with flat index
  const handleMouseMove = (groupIndex, commandIndex) => {
    setSelectedGroupIndex(groupIndex);
    setSelectedIndex(commandIndex);
  };

  // Add cleanup for security counters when palette closes
  useEffect(() => {
    if (!isOpen) {
      setRequestCount(0);
      setLastRequestTime(Date.now());
    }
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, ease: "easeOut" }}
            onClick={() => setIsOpen(false)}
            className="fixed inset-0 bg-black/60 backdrop-blur-sm z-50"
          />

          {/* Main container - removed slide animation, keeping fade and scale */}
          <div className="fixed inset-0 z-50 flex items-start justify-center pt-[10vh] sm:pt-[15vh]">
            <motion.div
              ref={commandPaletteRef}
              className="w-full max-w-2xl mx-4"
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              transition={{
                duration: 0.2,
                ease: [0.16, 1, 0.3, 1],
              }}
            >
              <div className={`
                ${isDarkMode 
                  ? 'bg-black/90 border-gray-800/50' 
                  : 'bg-white/90 border-gray-200/50'
                } 
                rounded-2xl border shadow-2xl backdrop-blur-xl overflow-hidden
                ring-1 ring-gray-800/5
              `}>
                {/* Search input with ESC indicator */}
                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className={`
                    p-4 border-b 
                    ${isDarkMode ? 'border-gray-800/50' : 'border-gray-200/50'}
                  `}
                >
                  <div className="flex items-center gap-3 px-3 py-2 rounded-lg bg-gray-900/5">
                    {searchQuery.toLowerCase().startsWith('blog:') ? (
                      <HiMagnifyingGlass className="w-5 h-5 text-gray-400" />
                    ) : calculationResult !== null ? (
                      <HiCalculator className="w-5 h-5 text-gray-400" />
                    ) : (
                      <HiOutlineCommandLine className="w-5 h-5 text-gray-400" />
                    )}
                    <input
                      type="text"
                      placeholder={
                        searchQuery.toLowerCase().startsWith('blog:') 
                          ? "Search essays by title or content..." 
                          : calculationResult !== null 
                            ? "Calculate anything..." 
                            : "Type a command or search..."
                      }
                      className={`
                        w-full bg-transparent border-none outline-none 
                        ${isDarkMode ? 'text-white' : 'text-gray-900'}
                        placeholder-gray-400 text-lg font-['Inter']
                        py-1 sm:py-0
                      `}
                      value={searchQuery}
                      onChange={(e) => {
                        const value = e.target.value;
                        setSearchQuery(value);
                        setSelectedIndex(0);
                        setSelectedGroupIndex(0);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          
                          // Get flattened commands array
                          const allCommands = filteredCommands.reduce((acc, group) => {
                            return [...acc, ...group.commands];
                          }, []);

                          // Get the selected command
                          const selectedCommand = allCommands[selectedIndex];
                          
                          // Execute the command if it exists
                          if (selectedCommand?.action) {
                            selectedCommand.action();
                          }
                        }
                      }}
                      autoFocus
                    />
                    <div className="flex items-center gap-2">
                      <kbd className={`
                        hidden sm:flex items-center justify-center
                        px-2 py-1 text-xs font-medium rounded font-['Inter']
                        ${isDarkMode 
                          ? 'bg-gray-800 text-gray-400' 
                          : 'bg-gray-200 text-gray-500'}
                      `}>
                        ESC
                      </kbd>
                    </div>
                  </div>
                </motion.div>

                {/* Commands list with groups */}
                <motion.div 
                  ref={commandsListRef}
                  className="max-h-[60vh] overflow-y-auto overflow-x-hidden"
                  initial="hidden"
                  animate="visible"
                  variants={{
                    hidden: { opacity: 0 },
                    visible: { opacity: 1 }
                  }}
                >
                  {filteredCommands.map((group, groupIndex) => (
                    <div key={group.name} className="mb-4">
                      {/* Group header */}
                      <div className={`
                        px-4 sm:px-6 py-2 text-xs font-medium tracking-wider font-['Inter']
                        ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}
                      `}>
                        {group.name.toUpperCase()}
                      </div>

                      {/* Group commands */}
                      {group.commands.map((command, index) => (
                        <motion.button
                          key={command?.id || index}
                          onClick={() => {
                            if (command?.action) {
                              command.action();
                            }
                          }}
                          onMouseMove={() => handleMouseMove(groupIndex, index)}
                          className={`
                            w-full px-4 sm:px-6 py-3 flex items-center font-['Inter']
                            group transition-all duration-75
                            ${copiedId === command?.id
                              ? isDarkMode 
                                ? 'bg-green-500/20' 
                                : 'bg-green-50'
                              : isDarkMode 
                                ? index === selectedIndex && groupIndex === selectedGroupIndex
                                  ? 'bg-gray-800/50' 
                                  : 'hover:bg-gray-800/50'
                                : index === selectedIndex && groupIndex === selectedGroupIndex
                                  ? 'bg-black/[0.03]' 
                                  : 'hover:bg-black/[0.03]'
                              }
                          `}
                        >
                          <div className="flex items-center gap-3 sm:gap-4 min-w-0 w-full">
                            {command?.icon && (
                              <div className={`
                                p-1.5 rounded-lg flex-shrink-0 flex items-center justify-center
                                w-8 h-8
                                ${copiedId === command?.id
                                  ? isDarkMode 
                                    ? 'bg-green-500/20 text-green-400' 
                                    : 'bg-green-100 text-green-600'
                                  : isDarkMode 
                                    ? 'bg-gray-800/50 text-gray-400 group-hover:text-gray-300' 
                                    : 'bg-gray-100/50 text-gray-500 group-hover:text-gray-600'
                                }
                                transition-colors
                              `}>
                                {typeof command.icon === 'function' 
                                  ? command.icon({ className: 'w-4 h-4' })
                                  : <command.icon className="w-4 h-4" />
                                }
                              </div>
                            )}
                            <div className="flex flex-col min-w-0 flex-1">
                              <span className={`
                                font-medium text-base text-left
                                ${copiedId === command?.id
                                  ? isDarkMode 
                                    ? 'text-green-400' 
                                    : 'text-green-600'
                                  : isDarkMode 
                                    ? 'text-white' 
                                    : 'text-gray-900'
                                }
                              `}>
                                {copiedId === command?.id ? 'Copied' : command?.name}
                              </span>
                              {command?.description && (
                                <span className={`
                                  text-sm text-left
                                  ${copiedId === command?.id
                                    ? isDarkMode 
                                      ? 'text-green-400/70' 
                                      : 'text-green-600/70'
                                    : 'text-gray-500 group-hover:text-gray-400'
                                  }
                                `}>
                                  {command.description}
                                </span>
                              )}
                              {command?.secondaryDescription && (
                                <span className={`
                                  text-sm text-left mt-1
                                  ${copiedId === command?.id
                                    ? isDarkMode 
                                      ? 'text-green-400/50' 
                                      : 'text-green-600/50'
                                    : isDarkMode 
                                      ? 'text-gray-500' 
                                      : 'text-gray-600'
                                  }
                                `}>
                                  {command.secondaryDescription}
                                </span>
                              )}
                            </div>
                          </div>
                        </motion.button>
                      ))}
                    </div>
                  ))}

                  {filteredCommands.length === 0 && (
                    <div className="px-6 py-12 text-left font-['Inter']">
                      <span className={`
                        text-lg font-medium
                        ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}
                      `}>
                        No commands found
                      </span>
                    </div>
                  )}
                </motion.div>
              </div>
            </motion.div>
          </div>
        </>
      )}
    </AnimatePresence>
  );
}

export default CommandPalette;
