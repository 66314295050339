import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';
import { FiGithub, FiMail, FiLinkedin, FiMoreHorizontal } from 'react-icons/fi';
import { FaSquareThreads } from "react-icons/fa6";
import { BsTwitterX } from "react-icons/bs";
import { useEasterEggs } from '../contexts/EasterEggContext';

const shimmerClass = `
  hover:animate-shimmer bg-clip-text hover:text-transparent 
  hover:bg-[linear-gradient(110deg,#000000,30%,#ffffff,45%,#000000,55%,#ffffff,70%,#000000)] 
  dark:hover:bg-[linear-gradient(110deg,#ffffff,30%,#1e293b,45%,#ffffff,55%,#1e293b,70%,#ffffff)]
  light:hover:bg-[linear-gradient(110deg,#000000,30%,#ffffff,45%,#000000,55%,#ffffff,70%,#000000)]
  hover:bg-[length:300%_100%] transition-all duration-300 ease-in-out
`;

const SOCIAL_LINKS = [
  { icon: FiMail, href: 'mailto:hi@prateekkeshari.com' },
  { icon: FiGithub, href: 'https://github.com/prateekkeshari' },
  { icon: BsTwitterX, href: 'https://twitter.com/prkeshari' },
  { icon: FiLinkedin, href: 'https://linkedin.com/in/prateekkeshari' },
  { icon: FaSquareThreads, href: 'https://www.threads.net/@prateekkeshari' }
];

const Footer = ({ isDarkMode }) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [colonVisible, setColonVisible] = useState(true);
  const [weatherData, setWeatherData] = useState(null);
  const [userLocation, setUserLocation] = useState('Berlin');
  const [isLoading, setIsLoading] = useState(true);
  const { totalPoints, progress, discoverEgg, discoveredEggs } = useEasterEggs();
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
      setColonVisible(prev => !prev);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) throw new Error('Location fetch failed');
        const data = await response.json();
        setUserLocation(data.city || 'Berlin');
      } catch (error) {
        console.error('Error fetching location:', error);
        setUserLocation('Berlin');
      }
    };
    fetchLocation();
  }, []);

  useEffect(() => {
    const fetchWeather = async () => {
      if (!userLocation) return;
      try {
        setIsLoading(true);
        const response = await fetch(`/api/weather?city=${userLocation}`);
        if (!response.ok) throw new Error('Weather fetch failed');
        const data = await response.json();
        setWeatherData(data);
      } catch (error) {
        console.error('Error fetching weather:', error);
        setWeatherData(null);
      } finally {
        setIsLoading(false);
      }
    };
    fetchWeather();
  }, [userLocation]);

  const handleWeatherHover = () => {
    if (!discoveredEggs.includes('WEATHER_EXPLORER')) {
      discoverEgg('WEATHER_EXPLORER');
    }
  };

  return (
    <motion.footer 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="mt-8 flex justify-center"
    >
      <div className={`
        flex items-center gap-3 px-4 py-2.5 rounded-lg w-full max-w-xl
        ${isDarkMode ? 'bg-black/40 text-gray-400' : 'bg-white/40 text-gray-500'}
        backdrop-blur-xl border relative
        ${isDarkMode ? 'border-white/[0.08]' : 'border-black/[0.08]'}
      `}>
        {/* Primary Info */}
        <div className="flex items-center gap-2 flex-1 min-w-0">
          <div className="w-1.5 h-1.5 rounded-full bg-green-500/60 flex-shrink-0" />
          <span className={`text-xs ${shimmerClass} hidden sm:inline`}>v2.0</span>
          <div className="h-3 w-[1px] bg-current opacity-10 hidden sm:block" />
          <div className="text-xs flex-shrink-0">
            {format(currentTime, 'HH')}
            <span className={colonVisible ? 'opacity-100' : 'opacity-0'}>:</span>
            {format(currentTime, 'mm')}
          </div>
          <div className="h-3 w-[1px] bg-current opacity-10" />
          {/* Weather */}
          <div 
            className="flex items-center gap-1.5"
            onMouseEnter={handleWeatherHover}
          >
            {isLoading ? (
              <div className="w-3.5 h-3.5 rounded-full bg-current/10 animate-pulse" />
            ) : weatherData && weatherData.main ? (
              <>
                <span className="text-xs">
                  {Math.round(weatherData.main.temp)}°C
                </span>
                <span className="text-xs opacity-50 group relative">
                  {userLocation}
                  <span className={`
                    absolute -top-8 left-1/2 -translate-x-1/2 
                    px-2 py-1 rounded text-[10px] whitespace-nowrap
                    ${isDarkMode ? 'bg-white/10' : 'bg-black/5'}
                    opacity-0 group-hover:opacity-100
                    transition-opacity duration-200
                    pointer-events-none
                  `}>
                    Current weather
                  </span>
                </span>
              </>
            ) : null}
          </div>
          <div className="h-3 w-[1px] bg-current opacity-10" />
          <div className="text-xs group relative flex-shrink-0">
            <span className="group-hover:opacity-40 transition-opacity">
              {totalPoints} / {progress.possiblePoints}
            </span>
            <span className={`
              absolute -top-8 left-1/2 -translate-x-1/2 
              px-2 py-1 rounded text-[10px] whitespace-nowrap
              ${isDarkMode ? 'bg-white/10' : 'bg-black/5'}
              opacity-0 group-hover:opacity-100
              transition-opacity duration-200
              pointer-events-none
            `}>
              Easter eggs discovered
            </span>
          </div>
        </div>

        {/* Social Links - Desktop */}
        <div className="hidden sm:flex items-center gap-2">
          <div className="h-3 w-[1px] bg-current opacity-10" />
          {SOCIAL_LINKS.map((link) => (
            <motion.a
              key={link.href}
              href={link.href}
              target={link.href.startsWith('mailto:') ? undefined : '_blank'}
              rel={link.href.startsWith('mailto:') ? undefined : 'noopener noreferrer'}
              className="hover:text-gray-300 transition-colors p-1"
              whileHover={{ y: -2 }}
              whileTap={{ scale: 0.95 }}
            >
              <link.icon className="w-3.5 h-3.5" />
            </motion.a>
          ))}
        </div>

        {/* Mobile More Button */}
        <div className="sm:hidden flex items-center">
          <div className="h-3 w-[1px] bg-current opacity-10" />
          <motion.button
            onClick={() => setShowMore(!showMore)}
            className="p-1.5 hover:text-gray-300 transition-colors"
            whileTap={{ scale: 0.95 }}
          >
            <FiMoreHorizontal className="w-3.5 h-3.5" />
          </motion.button>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {showMore && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              className={`
                absolute right-0 bottom-full mb-2 
                ${isDarkMode ? 'bg-black/90' : 'bg-white/90'}
                backdrop-blur-xl border
                ${isDarkMode ? 'border-white/[0.08]' : 'border-black/[0.08]'}
                rounded-lg overflow-hidden
              `}
            >
              <div className="p-2 flex flex-col gap-1">
                <div className="px-2 py-1 text-[10px] opacity-60 uppercase">Links</div>
                {SOCIAL_LINKS.map((link) => (
                  <motion.a
                    key={link.href}
                    href={link.href}
                    target={link.href.startsWith('mailto:') ? undefined : '_blank'}
                    rel={link.href.startsWith('mailto:') ? undefined : 'noopener noreferrer'}
                    className="flex items-center gap-2 px-2 py-1.5 hover:text-gray-300 transition-colors rounded"
                    whileHover={{ x: 2 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <link.icon className="w-3.5 h-3.5" />
                  </motion.a>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.footer>
  );
};

export default Footer;
