// Import all blog posts using webpack's require.context
const importAll = (r) => {
  return r.keys().map(fileName => {
    const slug = fileName.replace(/^\.\//, '').replace(/\.md$/, '');
    const content = r(fileName).default || r(fileName); // Handle both ESM and CommonJS
    return {
      slug,
      content
    };
  });
};

// Import all .md files from the posts directory
const blogPosts = importAll(require.context('../posts', false, /\.md$/));

export function getAllPosts() {
  return blogPosts
    .map(post => {
      try {
        // Extract frontmatter and content
        const [, frontmatter, content] = post.content.match(/^---([\s\S]*?)---\n([\s\S]*)$/);
        
        // Parse frontmatter
        const data = {};
        frontmatter.split('\n').forEach(line => {
          const [key, ...valueParts] = line.split(':');
          if (key && valueParts.length) {
            // Clean the value by removing quotes and extra whitespace
            const value = valueParts.join(':').trim().replace(/^["']|["']$/g, '');
            data[key.trim()] = value;
          }
        });

        // Validate date format
        if (data.date) {
          try {
            // Attempt to parse the date to validate it
            new Date(data.date).toISOString();
          } catch (error) {
            console.error(`Invalid date format in post ${post.slug}:`, error);
            data.date = null;
          }
        }

        return {
          slug: post.slug,
          content: content.trim(),
          ...data
        };
      } catch (error) {
        console.error(`Error parsing post ${post.slug}:`, error);
        console.log('Post content:', post.content);
        return null;
      }
    })
    .filter(Boolean) // Remove any null posts
    .sort((a, b) => new Date(b.date) - new Date(a.date));
} 